import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'user-account',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/user-account/user-account.module').then(m => m.UserAccountModule)
      },
      {
        path: 'request',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/request-app-form/request-app-form.module').then(m => m.RequestAppFormModule)
      },
      {
        path: 'admin-setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/admin-setting/admin-setting.module').then(m => m.AdminSettingModule)
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report/report.module').then(m => m.ReportModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //{ relativeLinkResolution: 'legacy' }
  exports: [RouterModule]
})
export class AppRoutingModule {}
