<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav
      #sidenav
      class="example-sidenav"
      [class.large-width]="mobileQuery || isExpanded"
      [mode]="mobileQuery ? 'over' : 'side'"
      [opened]="!mobileQuery"
      [disableClose]="!mobileQuery"
    >
      <div class="main-logo" routerLink="/">
        <div class="logo title">
          <img
            src="{{
            account?.schools?.profile_image
              ? (account.schools?.profile_image | staticFile) + '?image_size=m'
              : 'assets/imgs/logo.png'
          }}"
            width="60"
            height="60"
            alt="logo-image"
          />
          <span *ngIf="isExpanded">អង្គភាពច្រកចេញចូលតែមួយ រដ្ឋបាលរាជធានីភ្នំពេញ</span>
        </div>
      </div>
      <mat-nav-list class="menu-list mt-0" id="list-nav" [ngStyle]="{'margin-top': isExpanded ? '160px' : ''}">
        <div [ngClass]="{'pr-10 pl-10': isExpanded}">
          <div *ngFor="let m of menu; let last=last">
            <mat-list-item
              #tooltip="matTooltip"
              [matTooltip]="!isExpanded ? (m.title | translate) : ''"
              matTooltipPosition="right"
              *ngIf="m.child.length <= 0 "
              [routerLink]="m.route"
              [routerLinkActive]="m.route ? 'active' : ''"
              [routerLinkActiveOptions]="{exact:false}"
              [ngClass]="{'rounded-0': !isExpanded}"
            >
              <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate }}</span>
              <mat-icon
                matListItemIcon
                *ngIf="m?.icon || m?.svgIcon"
                [svgIcon]="m.svgIcon"
                [class.svg-active]="m.route && route.url.includes(m.route)"
              >
                {{m?.icon}}
              </mat-icon>
            </mat-list-item>

            <mat-expansion-panel
              *ngIf="m.child.length > 0 && isExpanded; else shrink"
              class="expansion-panel"
              [class.parents]="isChildActive(m.child)"
              [expanded]="last"
            >
              <mat-expansion-panel-header
                [class.active]="isChildActive(m.child)"
                #tooltipParent="matTooltip"
                [matTooltip]="!isExpanded ? (m.title) : ''"
                matTooltipPosition="right"
              >
                <mat-icon matListItemIcon *ngIf="m.icon !== ''">{{ m.icon }}</mat-icon>
                <mat-icon matListItemIcon *ngIf="m.svgIcon !== ''" [svgIcon]="m.svgIcon"></mat-icon>

                <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate }}</span>
              </mat-expansion-panel-header>
              <mat-nav-list class="expansion-panel-body">
                <mat-list-item
                  matTooltipPosition="right"
                  *ngFor="let c of m.child"
                  class=" {{ isExpanded ? 'expand' : 'no-expand' }}"
                  [routerLink]="c.route"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact:false}"
                  #tooltip="matTooltip"
                  [matTooltip]="!isExpanded ? (c.title) : ''"
                >
                  <span class="menu-item-title">{{ c.title | translate }}</span>
                  <mat-icon matListItemIcon *ngIf="c.icon !== '' && !isExpanded">{{ c.icon }}</mat-icon>
                  <mat-icon matListItemIcon *ngIf="c.svgIcon !== '' && !isExpanded" [svgIcon]="c.svgIcon"></mat-icon>
                </mat-list-item>
              </mat-nav-list>
            </mat-expansion-panel>

            <ng-template #shrink>
              <mat-list-item
                #tooltip="matTooltip"
                [matTooltip]="!isExpanded ? (m.title | translate) : ''"
                matTooltipPosition="right"
                *ngIf="m.child.length > 0 "
                [class.active]="isChildActive(m.child)"
                [matMenuTriggerFor]="narrow"
                [ngClass]="{'triangle': !isExpanded, 'rounded-0': !isExpanded}"
              >
                <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate }}</span>

                <mat-icon
                  matListItemIcon
                  *ngIf="m?.icon || m?.svgIcon"
                  [svgIcon]="m.svgIcon"
                  [class.svg-active]="m.route && route.url.includes(m.route)"
                >
                  {{m?.icon}}
                </mat-icon>
                <mat-icon iconPositionEnd>chevron_right</mat-icon>
              </mat-list-item>

              <mat-menu #narrow="matMenu" [overlapTrigger]="true" backdropClass="nav-mat-menu">
                <mat-nav-list class="expansion-panel-body">
                  <mat-list-item
                    *ngFor="let c of m.child"
                    [routerLink]="c.route"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:false}"
                  >
                    <span class="menu-item-title">{{ c.title | translate }}</span>
                  </mat-list-item>
                </mat-nav-list>
              </mat-menu>
            </ng-template>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content
      [class.explicit-sm-ml]="!mobileQuery && !isExpanded"
      [class.explicit-lg-ml]=" !mobileQuery  && isExpanded"
    >
      <div
        class="example-sidenav-content app-root-content"
        [class.nav-text-hidden]="!isExpanded"
        [class.nav-text-show]="isExpanded"
      >
        <mat-toolbar class="dashboard-toolbar" [class.blurred]="!isFirefox">
          <header class="dashboard__header" #toolbar>
            <button class="icon mr-8" mat-icon-button (click)="toggleSideNav()">
              <mat-icon svgIcon="menu-hamburger"></mat-icon>
            </button>
            <h1 routerLink="/">{{activeAcademicYear}}</h1>

            <div class="header-action">
              <!-- language -->
              <button
                class="languages"
                mat-icon-button
                [matMenuTriggerFor]="dropMenu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon svgIcon="{{browserLang === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
              </button>
              <mat-menu #dropMenu="matMenu">
                <div>
                  <button
                    mat-menu-item
                    *ngFor="let lang of langs | keyvalue: originalOrder"
                    (click)="useLanguage(lang.key)"
                  >
                    <mat-icon svgIcon="{{lang.key === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                    <div class="flex items-center gap-x-8">
                      <span>{{ lang.value }}</span>
                      @if (browserLang === lang.key) {
                        <span class="ml-auto"><mat-icon svgIcon="check"></mat-icon></span>
                      }
                    </div>
                  </button>
                </div>
              </mat-menu>

              <!-- notification -->
              <app-notification></app-notification>

              <section class="vertical-line"></section>

              <!-- profile -->
              <div class="avatar">
                <button mat-button class="profile-menu-btn" [matMenuTriggerFor]="beforeMenu">
                  <img
                    mat-card-avatar
                    class="avatar-profile"
                    alt="avatar"
                    [src]="account?.profile_image ? (account?.profile_image | staticFile) : 'assets/imgs/male.svg'"
                    onerror="this.onerror=null;this.src='assets/imgs/male.svg'"
                  />
                  <mat-icon class="arrow-down" iconPositionEnd svgIcon="arrow-down"></mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before" backdropClass="hover-profile">
                  <div class="profile-img ml-16 mr-16" mat-ripple routerLink="admin-setting/detail">
                    <img
                      mat-card-avatar
                      class="avatar-profile"
                      alt="avatar"
                      [src]="account?.profile_image ? (account?.profile_image | staticFile) : 'assets/imgs/male.svg'"
                      onerror="this.onerror=null;this.src='assets/imgs/male.svg'"
                    />
                    <span class="ml-8">
                      <span class="name"> {{(account | staffFullName) || 'Unknown'}}</span>
                      <span class="role">{{account?.roles | staffRole}}</span>
                    </span>
                  </div>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>ចាកចេញ</button>
                </mat-menu>
              </div>
            </div>
          </header>
        </mat-toolbar>

        <section class="dash-row" [ngStyle]="{'margin-top': toolbar.offsetHeight + 'px'}">
          <router-outlet></router-outlet>
        </section>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
